import { Dialog, Transition } from "@headlessui/react";
import { Spinner } from "@thedealersconcierge/components";
import { QRCodeSVG } from "qrcode.react";
import { FC, Fragment, useEffect, useState } from "react";
import { X } from "react-feather";
import Button from "~/components/design-system-components/Button";
import config from "~/config";
import { gqlMutationClient } from "~/lib/backend";

const QrCodeComponent: FC<{ transactionId: string }> = ({ transactionId }) => {
  const [token, setToken] = useState<string | null>(null);
  useEffect(() => {
    gqlMutationClient()({
      makeSubmissionToken: {
        __typename: true,
        "...on GraphQLError": {
          message: true,
        },
        "...on MutationMakeSubmissionTokenSuccess": {
          data: true,
        },
      },
    })
      .then((res) => {
        if (
          res.makeSubmissionToken?.__typename ===
          "MutationMakeSubmissionTokenSuccess"
        ) {
          setToken(res.makeSubmissionToken.data);
        }
      })
      .catch(console.error);

    return () => {
      setToken(null);
    };
  }, []);

  if (!token) {
    return (
      <div>
        <Spinner size="LARGE" color="GREY" />
      </div>
    );
  }
  const theUrl = `${config.consumerAppUrl}scanner/${transactionId}/${token}`;
  return (
    <div className="flex flex-col items-center space-y-4">
      <p>
        Scan the QR code with your phone camera to open the Document Scanner.
        Use it to scan documents and save them as PDFs directly from your phone.{" "}
      </p>
      <QRCodeSVG value={theUrl} size={256} />

      {!config.isProduction && (
        <>
          <a href={theUrl}>Open Scanner for DEV: </a>
          <p>{theUrl}</p>
        </>
      )}
    </div>
  );
};

type Props = {
  transactionId: string;
  refetchTransaction: () => void;
};

export default function OpenScannerModel({ transactionId }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        variant="SECONDARY"
      >
        Scan Documents
      </Button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-modal"
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25 backdrop-blur-sm" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="div"
                    className="flex flex-row justify-between items-center"
                  >
                    <h2 className="text-heading-2 font-bold">
                      Scan Documents{" "}
                    </h2>

                    <div
                      className="cursor-pointer px-2 py-2"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <X />
                    </div>
                  </Dialog.Title>

                  {isOpen && <QrCodeComponent transactionId={transactionId} />}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
