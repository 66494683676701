import { Dialog, Transition } from "@headlessui/react";
import { FinanceType } from "@thedealersconcierge/lib/codecs/tdc";
import { format } from "date-fns";
import { t } from "i18next";
import { Fragment } from "react/jsx-runtime";
import {
  TransactionLifecycle,
  VehicleLifeCycleStage,
} from "~/__generated__/backend/zeus";
import Banner from "~/components/Banner";
import Button from "~/components/Button";
import { gqlMutationClient } from "~/lib/backend";
import { getReadableFinanceType } from "~/lib/enumReadable";
import { TransactionQueryType } from "../_queries/transactionQuery";

type Props = {
  isOpen: boolean;
  onError: (msg: string) => void;
  onSuccess: () => void;
  onCancel: () => void;
  transactionId: string;
  forLifeCycle: TransactionLifecycle;
  transaction: TransactionQueryType["transaction"];
  lastRequestedAt: Date | "NEVER";
};

const readAbleTransactionCycle = (c: TransactionLifecycle) => {
  switch (c) {
    case TransactionLifecycle.POST_PURCHASE:
      return "Post-Purchase";
    case TransactionLifecycle.PRE_PURCHASE:
      return "Pre-Purchase";
  }
};

export default function ReqSignaturesConfirmModal({
  isOpen,
  onError,
  onSuccess,
  onCancel,
  transactionId,
  forLifeCycle,
  transaction,
  lastRequestedAt,
}: Props) {
  const requestSignature = async (lc: TransactionLifecycle) => {
    try {
      const resp = await gqlMutationClient()({
        cancelPreviousAndRequestSignatures: [
          {
            transactionId,
            lifeCycle: lc,
          },
          {
            __typename: true,
            "...on GraphQLError": {
              message: true,
            },
            "...on MutationCancelPreviousAndRequestSignaturesSuccess": {
              data: {
                status: true,
              },
            },
          },
        ],
      });

      if (
        resp.cancelPreviousAndRequestSignatures?.__typename ===
        "MutationCancelPreviousAndRequestSignaturesSuccess"
      ) {
        onSuccess();
      } else {
        onError(
          resp.cancelPreviousAndRequestSignatures?.message ?? "unexpected-error"
        );
      }
    } catch (e) {
      onError("Some error happened");
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={onCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div
          className="fixed inset-0 overflow-y-auto"
          data-test-id={"request-signature-confirm-modal-body"}
        >
          <div className="flex min-h-full items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={
                  "w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-6"
                }
              >
                <div className="pt-4 text-heading-3">
                  <Dialog.Title className="text-heading-2">
                    Launch {readAbleTransactionCycle(forLifeCycle)} Documents?
                  </Dialog.Title>
                </div>

                {lastRequestedAt !== "NEVER" && (
                  <Banner variant="WARNING" className="mt-4">
                    {t(
                      "Signatures for the forms have already been requested at {{dateString}}. If you proceed, a new signature request will be triggered, and all previously signed documents will be voided and deleted. The customer will need to log into the portal to sign the new set of forms.",
                      {
                        dateString:
                          lastRequestedAt instanceof Date
                            ? format(lastRequestedAt, "MM/dd/yyyy")
                            : "",
                      }
                    )}
                  </Banner>
                )}

                <p className="py-4 text-body-1">
                  You Are About to Launch{" "}
                  {readAbleTransactionCycle(forLifeCycle)} Documents For
                  e-Signatures. Verify the Information Below:
                </p>

                <div className="space-y-2 pb-4">
                  <p>
                    Finance Type:{" "}
                    <span className="font-bold">
                      {getReadableFinanceType(
                        FinanceType.optional()
                          .nullish()
                          .parse(transaction?.financeType) ?? undefined
                      )}
                    </span>
                  </p>
                  <p>
                    Vehicle for Purchase Type:{" "}
                    <span className="font-bold">
                      {transaction?.vehicle?.lifeCycleStage ===
                      VehicleLifeCycleStage.IS_USED
                        ? "Used"
                        : "New"}
                    </span>
                  </p>

                  <p>
                    Is LBO:{" "}
                    <span className="font-bold">
                      {transaction?.isLeaseBuyOut ? "Yes" : "No"}
                    </span>
                  </p>

                  <p>
                    Has trade Vehicle{" "}
                    <span className="font-bold">
                      {transaction?.tradeVehicle ? "Yes" : "No"}
                    </span>
                  </p>
                </div>

                <div className="flex justify-end space-x-2">
                  <Button
                    variant="SECONDARY"
                    onClick={onCancel}
                    dataTestId="request-signature-cancel-button"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="PRIMARY"
                    onClick={() => void requestSignature(forLifeCycle)}
                    dataTestId="request-signature-confirm-button"
                  >
                    Request Signatures
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
