import { ReactFormApi } from "@tanstack/react-form";
import { ZodValidator } from "@tanstack/zod-form-adapter";
import {
  Button,
  NumberInput,
  Select,
  TextInput,
} from "@thedealersconcierge/components";
import {
  VehicleCondition as VehicleConditionSchema,
  VehicleLifeCycleStage as VehicleLifeCycleStageSchema,
  VehiclePrincipalPriorUse as VehiclePrincipalPriorUseSchema,
} from "@thedealersconcierge/lib/codecs/schema/vehicle";
import { vinNumber } from "@thedealersconcierge/lib/codecs/validation/vinNumber";

import { VehicleBodyTypeSchema } from "@thedealersconcierge/lib/codecs/tdc";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import {
  VehicleBodyType,
  VehicleCondition,
  VehicleLifeCycleStage,
  VehiclePrincipalPriorUse,
  VehicleType,
} from "~/__generated__/backend/zeus";
import {
  stringToVehicleBodyType,
  stringToVehicleCondition,
  stringToVehicleLifeCycleStage,
  stringToVehiclePrincipalPriorUse,
} from "~/lib/enumMap";

export type VehicleFormType = {
  stockNumber?: string;
  vin: string;
  bodyType: VehicleBodyType | undefined;
  make: string;
  model: string;
  year: string;
  color: string;
  condition: VehicleCondition | undefined;
  mileage: string;
  trim: string;
  principalPriorUse: VehiclePrincipalPriorUse | undefined;
  lifeCycleStage: VehicleLifeCycleStage | undefined;
};

export const ValidVehicleSchema = (t: TFunction, vehicleType: VehicleType) =>
  z
    .object({
      // Required Field in BOTH Purchase & Trade
      vin: vinNumber(t("VIN numbers must have exactly 17 characters")),
      make: z.string().min(1, t("Make is required")),
      model: z.string().min(1, t("Model is required")),
      year: z.string().min(1, t("Year is required")),
      mileage: z.string().min(1, t("Mileage is required")),

      lifeCycleStage:
        vehicleType === VehicleType.PURCHASE
          ? VehicleLifeCycleStageSchema
          : VehicleLifeCycleStageSchema.optional(),

      stockNumber: z.string().optional(),
      color: z.string(),
      bodyType: VehicleBodyTypeSchema.optional(),
      condition: VehicleConditionSchema.optional(),
      trim: z.string().optional(),

      // Need prior refinement
      // We still this to nullable to
      principalPriorUse: VehiclePrincipalPriorUseSchema.optional(),
    })
    .refine(
      (data) => {
        if (
          data.lifeCycleStage === VehicleLifeCycleStage.IS_USED &&
          vehicleType === VehicleType.PURCHASE
        ) {
          return !!data.principalPriorUse;
        }
        return true;
      },
      {
        message: t(
          "Principal Prior Use is required for used PURCHASE vehicles"
        ),
        path: ["principalPriorUse"],
      }
    );

export default function VehicleForm({
  form,
  vehicleType,
  isSubmitting,
  handleCancel,
}: {
  form: ReactFormApi<VehicleFormType, ZodValidator>;
  vehicleType: VehicleType;
  isSubmitting: boolean;
  handleCancel: () => void;
}) {
  const { t } = useTranslation();
  return (
    <>
      <div className="grid grid-cols-2 gap-6 w-full">
        {vehicleType === VehicleType.TRADE && (
          <form.Field
            name="condition"
            validators={{
              onBlur: VehicleConditionSchema.optional(),
            }}
          >
            {(field) => {
              return (
                <Select
                  options={VehicleConditionSchema.options.map((o) => {
                    return {
                      value: o.value,
                      label: o.description ?? o.value,
                    };
                  })}
                  value={field.state.value ?? ""}
                  label={t("Condition")}
                  assistiveMessage={t("Select the correct option")}
                  placeholder={t("E.g., Excellent")}
                  required={false}
                  disabled={isSubmitting}
                  onSelect={(option) => {
                    field.handleChange(stringToVehicleCondition(option.value));
                  }}
                  onBlur={field.handleBlur}
                  errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                  dataTestId={`vehicle-form-condition-select`}
                />
              );
            }}
          </form.Field>
        )}

        {vehicleType === VehicleType.PURCHASE && (
          <form.Field name="principalPriorUse">
            {(field) => {
              return (
                <Select
                  options={VehiclePrincipalPriorUseSchema.options.map((o) => {
                    return {
                      value: o.value,
                      label: o.description ?? o.value,
                    };
                  })}
                  value={field.state.value ?? ""}
                  label={t("Principal Prior Use")}
                  assistiveMessage={t("Select the correct option")}
                  placeholder={t("E.g., Rental Vehicle")}
                  // Required does not work when the Field is Conditionally Rendered
                  // Somehow HTML will see it as Hidden form and throw `An invalid form control is not focusable`
                  // Validation is still done in refinement level on Submit
                  required={false}
                  disabled={isSubmitting}
                  onSelect={(option) => {
                    field.handleChange(
                      stringToVehiclePrincipalPriorUse(option.value)
                    );
                  }}
                  onBlur={field.handleBlur}
                  errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                  dataTestId={`vehicle-form-prioruse-select`}
                />
              );
            }}
          </form.Field>
        )}

        <form.Field
          name="lifeCycleStage"
          validators={{
            onBlur:
              vehicleType === VehicleType.PURCHASE
                ? VehicleLifeCycleStageSchema
                : VehicleLifeCycleStageSchema.optional(),
          }}
        >
          {(field) => {
            return (
              <Select
                options={VehicleLifeCycleStageSchema.options.map((o) => {
                  return {
                    value: o.value,
                    label: o.description ?? o.value,
                  };
                })}
                value={field.state.value}
                label={t("Type")}
                assistiveMessage={t("Select the correct option")}
                placeholder={t("E.g., New")}
                required={vehicleType === VehicleType.PURCHASE}
                disabled={isSubmitting}
                onSelect={(option) => {
                  field.handleChange(
                    stringToVehicleLifeCycleStage(option.value)
                  );
                }}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                dataTestId={`vehicle-form-lifeCycle-select`}
              />
            );
          }}
        </form.Field>

        <form.Field
          name="vin"
          validators={{
            onBlur: vinNumber(t("VIN numbers must have exactly 17 characters")),
          }}
        >
          {(field) => {
            return (
              <TextInput
                maxLength={17}
                value={field.state.value}
                label={t("VIN Number")}
                assistiveMessage={t("Type in the value")}
                placeholder={t("VIN Number")}
                required={true} // Required in Both Purchase & Trade
                disabled={isSubmitting}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                dataTestId={`vehicle-form-vin-input`}
              />
            );
          }}
        </form.Field>

        <form.Field
          name="bodyType"
          validators={{
            onBlur: VehicleBodyTypeSchema.optional(),
          }}
        >
          {(field) => {
            return (
              <Select
                options={VehicleBodyTypeSchema.options.map((o) => {
                  return {
                    value: o.value,
                    label: o.description ?? o.value,
                  };
                })}
                value={field.state.value ?? ""}
                label={t("Body Type")}
                assistiveMessage={t("Select the correct option")}
                placeholder={t("E.g., Coupe")}
                required={false}
                disabled={isSubmitting}
                onSelect={(option) => {
                  field.handleChange(stringToVehicleBodyType(option.value));
                }}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                dataTestId={`vehicle-form-bodyType-select`}
              />
            );
          }}
        </form.Field>

        <form.Field
          name="make"
          validators={{
            onBlur: z.string().min(1, t("Make is required")),
          }}
        >
          {(field) => {
            return (
              <TextInput
                value={field.state.value}
                label={t("Make")}
                assistiveMessage={t("Type in the value")}
                placeholder={t("Make")}
                required={true} // Required in both Purchase and Trade
                disabled={isSubmitting}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                dataTestId={`vehicle-form-make-input`}
              />
            );
          }}
        </form.Field>

        <form.Field
          name="model"
          validators={{
            onBlur: z.string().min(1, t("Model is required")),
          }}
        >
          {(field) => {
            return (
              <TextInput
                value={field.state.value}
                label={t("Model")}
                assistiveMessage={t("Type in the value")}
                placeholder={t("Model")}
                required={true} // Required in both Purchase and Trade
                disabled={isSubmitting}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                dataTestId={`vehicle-form-model-input`}
              />
            );
          }}
        </form.Field>

        <form.Field
          name="year"
          validators={{
            onBlur: z.string().min(1, t("Year is required")),
          }}
        >
          {(field) => {
            return (
              <TextInput
                value={field.state.value}
                label={t("Year")}
                assistiveMessage={t("Type in the value")}
                placeholder={t("E.g., 2023")}
                required={true} // Required in both Purchase and Trade
                disabled={isSubmitting}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                dataTestId={`vehicle-form-year-input`}
              />
            );
          }}
        </form.Field>

        {vehicleType === VehicleType.TRADE && (
          <form.Field
            name="color"
            validators={{
              onBlur: z.string(),
            }}
          >
            {(field) => {
              return (
                <TextInput
                  value={field.state.value}
                  label="Color"
                  assistiveMessage="Type in the value"
                  placeholder="E.g., White"
                  required={false}
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                  dataTestId={`vehicle-form-color-input`}
                />
              );
            }}
          </form.Field>
        )}

        <form.Field
          name="mileage"
          validators={{
            onBlur: z.string().min(1, t("Mileage is required")),
          }}
        >
          {(field) => {
            return (
              <NumberInput
                value={field.state.value}
                label={t("Mileage")}
                assistiveMessage={t("Type in the value")}
                placeholder={t("E.g., 32,004")}
                required={true}
                disabled={isSubmitting}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                dataTestId={`vehicle-form-mileage-input`}
              />
            );
          }}
        </form.Field>

        {vehicleType === VehicleType.PURCHASE && (
          <form.Field
            name="trim"
            validators={{
              onBlur: z.string().optional(),
            }}
          >
            {(field) => {
              return (
                <TextInput
                  value={field.state.value}
                  label={t("Trim")}
                  assistiveMessage={t("Type in the value")}
                  placeholder={t("E.g., Touring")}
                  required={false}
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                  dataTestId={`vehicle-trim-mileage-input`}
                />
              );
            }}
          </form.Field>
        )}
      </div>

      <div className="flex flex-row justify-between">
        <Button
          label={t("Cancel")}
          variant="SECONDARY"
          type="button"
          disabled={isSubmitting}
          onClick={handleCancel}
        />

        <Button label={t("Save")} type="submit" disabled={isSubmitting} />
      </div>
    </>
  );
}
