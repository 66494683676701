import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { VehicleLifeCycleStage } from "~/__generated__/backend/zeus";
import Button from "~/components/Button";
import SectionDataRow from "~/components/SectionDataRow";
import VehicleIcon from "~/components/icons/VehicleIcon";
import {
  getReadableBodyType,
  getReadablePrincipalPriorUse,
} from "~/lib/enumReadable";
import { useNavigate, useParams } from "~/router";
import { dealershipAtom } from "~/state";
import { transactionQuery } from "../_queries/transactionQuery";
import RecallCheckModal from "./RecallCheckModal";

const TransactionPurchaseVehicle = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dealership] = useAtom(dealershipAtom);
  const { transactionId } = useParams("/dashboard/transaction/:transactionId");
  const { data } = useQuery(
    transactionQuery(transactionId, dealership?.activeDealershipPerms)
  );
  const transaction = data?.transaction;

  const purchaseVehicle = transaction?.vehicle;

  const handleEditPurchaseVehicle = () => {
    navigate(
      {
        pathname: "/dashboard/transaction/:transactionId/vehicle/purchase",
      },
      { params: { transactionId } }
    );
  };

  return (
    <div className="rounded-2xl bg-white shadow-md p-8 space-y-8">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row space-x-4 justify-between w-full">
          <div className="flex flex-row space-x-2 items-center">
            <div className="relative">
              <VehicleIcon className="w-5 aspect-square text-dark-gray" />
            </div>

            <h2 className="text-heading-2 text-very-dark-gray">
              {t("Vehicle for Purchase")}
            </h2>
          </div>

          <div className="flex flex-row space-x-4">
            {purchaseVehicle?.id && (
              <RecallCheckModal
                vehicleRole="PURCHASE"
                transactionId={transactionId}
              />
            )}

            <Button
              variant="TEXT_ONLY"
              onClick={() => {
                handleEditPurchaseVehicle();
              }}
            >
              {purchaseVehicle ? t("Edit") : t("Add")}
            </Button>
            {/**
             * TODO: Hook up CARFAX
             */}
            {/* <Button variant="SECONDARY">CARFAX</Button> */}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 lg:grid-cols-3">
        <div className="flex flex-col gap-2">
          <SectionDataRow
            label="Type"
            value={(() => {
              if (!purchaseVehicle) return "-";
              if (!purchaseVehicle?.lifeCycleStage) return "-";

              if (
                purchaseVehicle.lifeCycleStage === VehicleLifeCycleStage.IS_USED
              ) {
                return "Used";
              }
              return "New";
            })()}
          />

          <SectionDataRow
            label="Principal Prior Use"
            value={
              purchaseVehicle?.principalPriorUse
                ? getReadablePrincipalPriorUse(
                    purchaseVehicle.principalPriorUse
                  )
                : "-"
            }
          />

          <SectionDataRow
            label="Stock No."
            value={purchaseVehicle?.stockNumber ?? "-"}
          />

          <SectionDataRow label="VIN#" value={purchaseVehicle?.vin ?? "-"} />

          <SectionDataRow
            label="Body Type"
            value={
              purchaseVehicle?.bodyType
                ? getReadableBodyType(purchaseVehicle.bodyType)
                : "-"
            }
          />
        </div>

        <div className="flex flex-col gap-2">
          <SectionDataRow label="Make" value={purchaseVehicle?.make ?? "-"} />

          <SectionDataRow label="Model" value={purchaseVehicle?.model ?? "-"} />

          <SectionDataRow label="Year" value={purchaseVehicle?.year ?? "-"} />

          <SectionDataRow
            label="Mileage"
            value={purchaseVehicle?.mileage?.toLocaleString() ?? "-"}
          />

          <SectionDataRow label="Trim" value={purchaseVehicle?.trim ?? "-"} />
        </div>
      </div>
    </div>
  );
};

export default TransactionPurchaseVehicle;
