// Some of these utilities are reused in the costumer database / files
// TODO: extract more components to be reused in the costumer db

import { CdkDealJacketStatus } from "@thedealersconcierge/lib/codecs/tdc";
import { Pagination } from "~/pages/(authenticated)/dashboard/_dashboardUtils";

export type DocumentLine = {
  id: string;
  userId: string;
  displayTitle: string;
  type: string;
  createdAt: Date;
  access: string;
  name: string;
  isDocument: boolean;
  file?: {
    id: string;
    url: string;
  };
  cdkDealJacketStatus?: string;
};

export type FormSubmissionGroup = {
  type: string;
  formSubmissions: DocumentLine[];
};

export const handlePrintPrompt = (pdf: Blob) => {
  const url = URL.createObjectURL(pdf);

  // Create an iframe and append it to the body
  // iframe is used so that the print prompt can be opened on same page
  const iframe = document.createElement("iframe");
  iframe.style.display = "none"; // Make the iframe invisible
  iframe.src = url;
  document.body.appendChild(iframe);

  // Wait for the iframe to load and trigger the print
  iframe.onload = function () {
    if (iframe.contentWindow) iframe.contentWindow.print();

    // Remove the iframe after the print prompt is opened
    iframe.onload = function () {
      document.body.removeChild(iframe);
    };
  };
};

export const getHumanReadableCdkDealJacketStatus = (
  status: CdkDealJacketStatus
) => {
  switch (status) {
    case "PUSHED":
      return "Pushed";
    case "STAGED_FOR_PUSHING":
      return "In progress";
    case "FAILED":
      return "Failed";
    default:
      return status;
  }
};

// Paginations
// The pagination is pre-generated since we are handling 2 object at the same time
// It is done so it reduce complexity of the page calculations
export type PaginationStateWrapper = {
  fs: Pagination;
  doc: Pagination;
};

export type PageComposition = {
  fs: {
    pageSize: number;
    currentPage: number;
  };
  doc: {
    pageSize: number;
    currentPage: number;
  };
};

export const generatePageCompositions = (
  totalFs: number,
  totalDocs: number,
  itemsPerPage: number
): PageComposition[] => {
  const pages: PageComposition[] = [];
  let remainingFs = totalFs;
  let remainingDocs = totalDocs;
  let fsCurrentPage = 1;
  let docCurrentPage = 1;

  while (remainingFs > 0 || remainingDocs > 0) {
    const fsPageSize = Math.min(remainingFs, itemsPerPage);
    const docPageSize = Math.min(remainingDocs, itemsPerPage - fsPageSize);

    pages.push({
      fs: {
        pageSize: fsPageSize,
        currentPage: fsPageSize > 0 ? fsCurrentPage : fsCurrentPage - 1,
      },
      doc: {
        pageSize: docPageSize,
        currentPage: docPageSize > 0 ? docCurrentPage : docCurrentPage - 1,
      },
    });

    if (fsPageSize > 0) fsCurrentPage++;
    if (docPageSize > 0) docCurrentPage++;

    remainingFs -= fsPageSize;
    remainingDocs -= docPageSize;
  }

  return pages;
};
