import { Routes } from "@generouted/react-router";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
import { Provider } from "jotai";
import { RESET } from "jotai/utils";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import logoutAction from "./actions/logoutAction";
import config from "./config";
import "./i18n";
import "./index.css";
import { queryClient } from "./lib/query";
import store from "./lib/store";
import { authStateAtom, dealershipAtom } from "./state";

Sentry.init({
  ignoreErrors: [
    // For this error in particular, we can't really do much when requests fail due to network errors.
    // We can implement a network checker if it keeps being a problem
    "NetworkError: Load failed",
  ],

  dsn: "https://8c19d4bd0c8d9b921bd9c1324f7ba7e7@o4506535389626368.ingest.sentry.io/4506535445397504",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.NODE_ENV !== "development" && config.prodEnv !== "ci",
  environment: config.prodEnv,
});

// Configure Amplify for auth
if (config.cognito.userPoolId && config.cognito.clientId) {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: config.cognito.userPoolId,
        userPoolClientId: config.cognito.clientId,
      },
    },
  });

  Hub.listen("auth", ({ payload }) => {
    switch (payload.event) {
      case "signedIn":
        console.log("user signed in");
        store.instance.set(authStateAtom, {
          type: "cognito",
          userId: payload.data.userId,
        });
        break;
      case "signedOut":
        // Reset stored state
        store.instance.set(authStateAtom, RESET);
        store.instance.set(dealershipAtom, RESET);

        break;
      case "tokenRefresh":
        break;
      case "tokenRefresh_failure":
        console.error("failure while refreshing auth tokens.");
        break;
      case "signInWithRedirect":
        break;
      case "signInWithRedirect_failure":
        console.error(
          "failure while trying to resolve signInWithRedirect API."
        );
        break;
      case "customOAuthState":
        break;
    }
  });
} else {
  console.log("Cognito user pool not configured");
}

/**********
 * Log out when the page was closed
 *
 * git checkout -b madsbuch/sc-1274/as-a-dashboard-user-i-want-to-be-logged-out
 *
 * There is a good change that this does not work effectively. Therefore, remove
 * it, if it doesn't work properly
 */
const tabsOpen = Number(localStorage.getItem("tabsOpen"));
localStorage.setItem("tabsOpen", `${tabsOpen + 1}`);

const pageAccessedByReload =
  // Disables as the condition seems to be about node environments
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  (window.performance.navigation && window.performance.navigation.type === 1) ||
  window.performance
    .getEntriesByType("navigation")
    .map((nav) => nav.entryType)
    .includes("reload");

//  If there was not previous tabs open, and we did not approach the page by
// reload, that means it was previously closed
if (!pageAccessedByReload && tabsOpen === 0) {
  console.log("Page was closed, logging out");
  void logoutAction();
}

window.onbeforeunload = () => {
  const tabsOpen = Number(localStorage.getItem("tabsOpen"));
  localStorage.setItem("tabsOpen", `${Math.max(tabsOpen - 1, 0)}`);
};

/**********  END Log out when the page was closed */

// Render our app!
const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("Could not find root element");
}
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <Sentry.ErrorBoundary>
      <React.StrictMode>
        <Provider store={store.instance}>
          <QueryClientProvider client={queryClient}>
            <Routes />
          </QueryClientProvider>
        </Provider>
      </React.StrictMode>
    </Sentry.ErrorBoundary>
  );
}
